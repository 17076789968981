<template>
  <div>
    <fm-input-new v-loadingx="loading" style="width: 5rem;" @change="change" v-model="sortNum" size="small"></fm-input-new>
  </div>
</template>

<script>
import { workerRequest } from '@/api'

export default {
  props: {
    workerData: {
      type: Object
    }
  },
  watch: {
    workerData: {
      handler () {
        this.sortNum = this.workerData && this.workerData.position && !isNaN(this.workerData.position) ? this.workerData.position : null
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      sortNum: null,
      loading: false
    }
  },
  methods: {
    async change (value) {
      if (!this.workerData || !this.workerData.id) {
        this.$notice.error({
          title: '系统提示',
          desc: '数据异常。'
        })
        return
      }
      if (value && isNaN(value)) {
        this.sortNum = this.workerData && this.workerData.position && !isNaN(this.workerData.position) ? this.workerData.position : null
        this.$notice.info({
          title: '系统提示',
          desc: '请输入有效数值。'
        })
      } else {
        this.loading = true
        await workerRequest.update(this.workerData.id, {
          position: this.sortNum
        })
        this.$notice.success({
          title: '系统提示',
          desc: '默认排序修改完成。'
        })
        this.$emit('sortNumChange', this.sortNum)
        this.loading = false
      }
    }
  }
}
</script>
